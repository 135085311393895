
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect, useState, useMemo } from "react";
import "./EditCommunity.scss";
import useHttp from "App/hooks/use-http";
import { useNavigate } from "react-router-dom";
import closeIcon from "Assets/Icons/close.svg";
import { objectToFormdata, scrollToFirstInvalidField, updateElementData } from "App/helpers/utilities";
import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import Button from "App/Components/UI/Button";
import Validators from "App/Components/UI/Forms/Validators";
import TextArea from "App/Components/UI/TextArea";
import InputBox from "App/Components/UI/Forms/InputBox";
import ImageUploadWithCrop from "App/Components/ImageUploadWithCrop";
import Tabs from "App/Components/Tabs";
import CollectionsLists from "App/Components/CollectionsLists";
import useCommunityHelper from "../CommunityList/APIHelper";
import validationCheck from "App/helpers/validationCheck";
const EditCommunity = ({id,onClosePopup,onRefetchList,isCurator,type}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [formValues, setFormvalues] = useState({});
  const formGroup = useMemo(() => {
    return {
      name: {
        value: undefined,
        isRequired: true,
      },
      bannerPictureFile: {
        value: undefined,
        isRequired: true,
      },
      displayPictureFile: {
        value: undefined,
        isRequired: true,
      },
      description: {
        value: undefined,
        isRequired: true,
      },
      about: {
        value: undefined,
        isRequired: false,
      },
      instagram: {
        value: undefined,
        isRequired: false,
      },
      twitter: {
        value: undefined,
        isRequired: false,
      },
      website: {
        value: undefined,
        isRequired: false,
      },
      discord: {
        value: undefined,
        isRequired: false,
      },
      youtube: {
        value: undefined,
        isRequired: false,
      },
      blockChain: {
        value: undefined,
        isRequired: false,
      },
      tokenName: {
        value: undefined,
        isRequired: false,
      },
      tokenId: {
        value: undefined,
        isRequired: false,
      },
    };
  }, []);
  useEffect(() => {
    setFormvalues(getInitial(formGroup));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const { id } = useParams();
  const { triggerAPI } = useHttp();
  const [{communityThumbnailData},dispatch] = useStore();
  const [formVaildState, setFormVaildState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [displayPicture, setDisplayPicture] = useState(null);
  const [bannerPicture, setBannerPicture] = useState(null);
  const [collectionFilePath, setCollectionFilePath] = useState(null);
  const [blockChains, setBlockChains] = useState(null);
  const [isUserAddedAtLeastOneSocialURL, setIsUserAddedAtLeastOneSocialURL] = useState(true);
  const [totalCollectionsCount,setTotalCollectionsCount]=useState(0);
  const [collectionsDataLists,setCollectionsDataLists] = useState(null);
  const [collectionLoader,setCollectionLoader] = useState(true);
  const [collectionPage, setCollectionPage] = useState(1);
  const [activeTab, setActiveTab] = useState("community");
  const menuTabs = useMemo(()=>{
    let tabList= [{ id: "community", element: 'Community'}]
    if(id) tabList.push({ id: "collections", element: 'Collections'})
    return tabList
  },[id]);
  let navigate = useNavigate();
  const {getUserCollectionDataAPI} =useCommunityHelper();
  const collectionItemsPerPage=16;
  const getCollectionList =  (pageNo) => {
    setCollectionLoader(true);
    const page=pageNo || collectionPage;
    let dataParam = {
      page,
      items: collectionItemsPerPage,
      search: "",
      filter: {   orderBy:"DESC",
        sortBy:"mintDate",  },
    };
      dataParam={...dataParam,filter:{...dataParam?.filter,communityId: [parseInt(id)],status: "active"}}
    getUserCollectionDataAPI(dataParam, (res) => {
      const { list, pageMeta } = res;
      setTotalCollectionsCount(pageMeta?.totalItems);
      setCollectionsDataLists((prev) =>
        page ===1 ? [...list] : [...prev, ...list]
      );
      setCollectionLoader(false)
    });
  };
  const loadMoreCollections = (e) => {
    if (!collectionLoader) {
      getCollectionList(collectionPage + 1);
      setCollectionPage((pre) => pre + 1);
    }
  };

  useEffect(() => {
    if (id&&activeTab==="collections"&&!collectionsDataLists) {
      getCollectionList(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id,activeTab]);

  const gotToBack = () => {
    if(onClosePopup){
      onClosePopup();
    }else{
      navigate(-1);
    }
  };

  useEffect(() => {
    if (id) {
      if(type==="curator"){
        getDetailedCurators()
      }
      else getDetailedCommunity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const getDetailedCommunityResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const { data } = res;
      const fungibleTokens = data?.fungibleTokens?.length
        ? data?.fungibleTokens[0]
        : {};
      setFormvalues({
        name: data?.name ?? '',
        description: data?.description ?? '',
        about: data?.about ?? '',
        website: data?.website ?? '',
        youtube: data?.youtube ?? '',
        twitter: data?.twitter,
        instagram: data?.instagram ?? '',
        discord: data?.discord ?? '',
        tokenName: fungibleTokens?.tokenName ?? '',
        tokenId: fungibleTokens?.policyId ?? '',
        blockChain: fungibleTokens?.blockchainTypeId ?? '',
        bannerPictureFile: data?.bannerPicture ?? '',
        displayPictureFile: data?.displayPicture ?? '',
      });
      setBannerPicture(data?.bannerPicture);
      setDisplayPicture(data?.displayPicture);
      setCollectionFilePath(data?.collectionFilePath256);
      setBlockChains(data?.blockchain);
    },
    [dispatch]
  );
  const getDetailedCurators = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `curator/get/${id}`,
        method: "get",
      },
      getDetailedCommunityResult,()=>{
        dispatch("hideSpinner");
      }
    );
  }, [dispatch, getDetailedCommunityResult, triggerAPI, id]);
  const getDetailedCommunity = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/get/${id}`,
        method: "get",
      },
      getDetailedCommunityResult,()=>{
        dispatch("hideSpinner");
      }
    );
  }, [dispatch, getDetailedCommunityResult, triggerAPI, id]);

  useEffect(() => {
    if (touched) {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    setIsValid(valid);
  }, [formValues, formVaildState, formGroup]);

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);
  const inputOnChange = (e) => {
    updateElementData(e);
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
    if(!isUserAddedAtLeastOneSocialURL) {
      setIsUserAddedAtLeastOneSocialURL(true)
  }
  };

  const successResponse = () => {
    gotToBack();
    dispatch("hideSpinner");
    dispatch("showToast", {
      toast: { toastMode: "success", message: 'Community updated.' },
    });
    onRefetchList && onRefetchList();
  }
  const getResult = useCallback(
    (res) => {
      const { data } = res;
      dispatch('setCommunityThumbnailData', {
        blockChains: blockChains,
        coverImage: data?.bannerPicture,
        profileImage: data?.displayPicture256,
        collectionList: collectionFilePath,
        creatorName: data?.name,
        id: data?.id,
      })
      successResponse();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockChains, collectionFilePath]
  );
  const handleCheckUserSocials = (data) => {
    const filledAtLeastOne = !!data?.website || !!data?.twitter || !!data?.instagram || !!data?.youtube || !!data?.discord
   setIsUserAddedAtLeastOneSocialURL(filledAtLeastOne)
   return filledAtLeastOne;
 }
  const saveHandler = useCallback(() => {
    if(!!communityThumbnailData) {
      dispatch("showToast", {
        toast: {
          toastMode: "success",
          message: "Thumbnail genrating in progress"
        },
      });
      return false;
    }
    if (isValid && handleCheckUserSocials(formValues)) {
      dispatch("showSpinner");
      const list = { ...formValues };
      list.fungibleTokens = [];
      let tempFungibleTokens = {};
      if (list?.tokenName) tempFungibleTokens.tokenName = list?.tokenName;
      if (list?.tokenId) tempFungibleTokens.policyId = list?.tokenId;
      if (list?.blockChain)
        tempFungibleTokens.blockchainTypeId = list?.blockChain;
      if (Object.keys(tempFungibleTokens).length)
        list.fungibleTokens.push(tempFungibleTokens);
      if (
        !list?.bannerPictureFile ||
        typeof list?.bannerPictureFile === "string"
      )
        delete list.bannerPictureFile;
      if (
        !list?.displayPictureFile ||
        typeof list?.displayPictureFile === "string"
      )
        delete list.displayPictureFile;
      if (!list.fungibleTokens?.length) delete list.fungibleTokens;
      delete list.tokenName;
      delete list.tokenId;
      delete list.blockChain;
      const formData = objectToFormdata(list);
      triggerAPI(
        {
          url: type==="curator"?`curator/${id}`:id?`community/${id}`:"community/create",
          data: formData,
          method: id?"put":"post",
        },
        getResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message:
                err?.response?.data?.error?.message ||
                "Editing community failed",
            },
          });
        }
      );
    } else {
      setTouched(true);
      scrollToFirstInvalidField();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formValues, getResult, isValid, triggerAPI, id, communityThumbnailData]);

  const handleCroppedImage = (blobData, imageUrl, callback) => {
    if (!!blobData) {
      if (blobData?.bannerPicture) {
        inputOnChange({ bannerPictureFile: blobData?.bannerPicture });
        setBannerPicture(imageUrl);
      }
      if (blobData?.displayPictureFile) {
        inputOnChange({ displayPictureFile: blobData?.displayPictureFile });
        setDisplayPicture(imageUrl);
      }
      callback?.()
    } else {
      dispatch("showToast", {
        toast: {
          toastMode: "error",
          message: "Please upload an image",
        },
      });
    }
  }
  const handleTabChange = (e) => {
    setActiveTab(e)
  }
  return (
    <div className="community-form community-edit text-left flex flex-col gap-4">
      <div className="flex gap-2 items-center pl-4 pr-8 justify-between">
        {/* <img
          className="breadcrumbs-img"
          src={ArrowLeft}
          alt="img"
          onClick={gotToBack}
        /> */}
        <h2>{formValues.name}</h2>
        <div onClick={gotToBack} className="cursor-pointer"><img src={closeIcon} alt="close" /></div>
      </div>

      {!!(type==="curator")&&<div className="px-4">
          <div className="section-title brd-btm w-full">Curator</div>
        </div>}
        {!(type==="curator")&&<Tabs tabList={menuTabs} containerClass={`p-4`} selectedTab={activeTab} handleTab={handleTabChange} />}
        <div id="community-details-wrap" className="content-wrapper">
{ !!(activeTab==="community")&& <>
  <div className="flex flex-col gap-4 ">
        <div className="flex flex-col gap-4 ">
          <div className="flex gap-4 sm:flex-row flex-col">
            <div className="w-full flex justify-between sm:flex-row flex-col-reverse gap-6">
              <div className="sm:w-3/5 w-full flex flex-col gap-4 ">
              <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-col gap-2"
               data-required="true" data-name="name" 
               data-empty={!formValues.name ? 'true' : 'false'}
              >
                <div>
                  <InputBox
                    maxlength={35}
                    type="text"
                    label={"Name"}
                    required={true}
                    validateOnBlur
                    placeholder={`Profile Name/Project Name/${type==="curator"?"Curator": "Community"} Name`}
                    onChange={inputOnChange}
                    controlName="name"
                    value={formValues.name}
                  />
                </div>
                <Validators
                    type="Required"
                    value={formValues?.name}
                    message="Required field"
                    controlName="name"
                    onErrors={collectErrors}
                  />
              </div>
              <div className="flex flex-col gap-2"
               data-required="true" data-description="name" 
               data-empty={!formValues.description ? 'true' : 'false'}
              >
                <div>
                  <TextArea
                    placeholder={
                      "Describe the token including what it is, are potential benefits, roadmap (1000 words max)"
                    }
                    label="Description"
                    showTextCount={true}
                    validateOnBlur
                    required={false}
                    type="text"
                    onChange={inputOnChange}
                    maxLength={type==="curator"?1000:5000}
                    controlName="description"
                    value={formValues.description}
                  />
                </div>
                <Validators
                    type="Required"
                    value={formValues?.description}
                    message="Required field"
                    controlName="description"
                    onErrors={collectErrors}
                  />
              </div>
            </div>
              </div>
              <div className="sm:w-2/5 w-full flex sm:gap-[2.5rem] gap-[2px]  flex-col">
              <div className="flex flex-col gap-4"
               data-required="true" data-name="displayPictureFile" 
               data-empty={!formValues.displayPictureFile ? 'true' : 'false'}
              >
                  <ImageUploadWithCrop
                    imageHandle={handleCroppedImage}
                    uploadedImage={displayPicture}
                    label="Profile"
                    type={1}
                    inputId="upload-button-displayPicture"
                    keyName="displayPictureFile"
                    id="pro-pic"
                    isCropAspect={4 / 4}
                    recommendedSize={`512px x 512px`}
                    canvasStyle={{
                      maxWidth: '12rem',
                      maxHeight: '12rem',
                      borderRadius: '50%',
                      margin: 'auto'
                    }}
                  />
                <Validators
                    type="Required"
                    value={formValues?.displayPictureFile}
                    message="Required field"
                    controlName="displayPictureFile"
                    onErrors={collectErrors}
                  />
              </div>
            <div className="flex flex-col gap-4">
            <div className="upload-banner flex flex-col gap-2"
             data-required="true" data-name="bannerPictureFile" 
             data-empty={!formValues.bannerPictureFile ? 'true' : 'false'}
            >
              <ImageUploadWithCrop
                imageHandle={handleCroppedImage}
                uploadedImage={bannerPicture}
                label="Banner"
                inputId="upload-button-banner"
                keyName="bannerPicture"
                id="banner"
                croppedLabel="Banner"
                isCropAspect={16 / 3.484}
                recommendedSize={`1200px x 240px`}
                canvasStyle={{
                  objectFit: "cover",
                  borderRadius: '12px',
                  maxWidth: '100%',
                  margin: 'auto'
                }}
              />

              <Validators
                type="Required"
                value={formValues?.bannerPictureFile}
                message="Required field"
                controlName="bannerPictureFile"
                onErrors={collectErrors}
              />
            </div>
        </div>
              </div>
            </div>
 

          </div>
        </div>
        {/* <div className="flex flex-col gap-2">
          <div>
            <TextArea
              placeholder={
                "Describe the token including what it is, are potential benefits, roadmap (1000 words max)"
              }
              rows={10}
              label="About Us"
              required={true}
              showTextCount={true}
              validateOnBlur
              type="text"
              onChange={inputOnChange}
              maxLength={300}
              controlName="about"
              value={formValues.about}
            />
          </div>
        </div> */}
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 brd-btm w-full">
          <div className="section-title">{`Socials (Provide at least one)`}</div>
          {!isUserAddedAtLeastOneSocialURL && <div className="error-validation"><p>{`Provide at least one social account`}</p></div>}
        </div>
        <div className="flex flex-col w-full gap-4">
          <div className="flex w-full gap-4">
            <div className="flex w-full flex-col gap-2"
             data-name="website" 
             data-type="url"
             data-invalid={formValues?.website?
               validationCheck({ type: 'url', value:formValues?.website })?'true':'false':'false'}
            >
              <div>
                <InputBox
                  label="Website"
                  validateOnBlur
                  required={false}
                  placeholder="https://www.website.com"
                  type="text"
                  onChange={inputOnChange}
                  controlName="website"
                  value={formValues.website}
                />
                <Validators
                  type="url"
                  value={formValues.website}
                  message="URL not valid"
                  controlName="website"
                  onErrors={collectErrors}
                />
              </div>
            </div>
            <div className="flex w-full  flex-col gap-2"
             data-name="twitter" 
             data-type="twitterUrl"
             data-invalid={formValues?.twitter?
               validationCheck({ type: 'twitterUrl', value:formValues?.twitter })?'true':'false':'false'}
            >
              <div>
                <InputBox
                  label="X"
                  validateOnBlur
                  required={false}
                  placeholder="X URL"
                  type="text"
                  onChange={inputOnChange}
                  controlName="twitter"
                  value={formValues.twitter}
                />
                <Validators
                  type="twitterUrl"
                  value={formValues.twitter}
                  message="URL not valid"
                  controlName="twitter"
                  onErrors={collectErrors}
                />
              </div>
            </div>
            <div className="flex w-full  flex-col gap-2"
             data-name="instagram" 
             data-type="instagramUrl"
             data-invalid={formValues?.instagram?
               validationCheck({ type: 'instagramUrl', value:formValues?.instagram })?'true':'false':'false'}
            >
              <div>
                <InputBox
                  label="Instagram"
                  validateOnBlur
                  required={false}
                  placeholder="Instagram URL"
                  type="text"
                  onChange={inputOnChange}
                  controlName="instagram"
                  value={formValues.instagram}
                />
                <Validators
                  type="instagramUrl"
                  value={formValues.instagram}
                  message="URL not valid"
                  controlName="instagram"
                  onErrors={collectErrors}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col sm:flex-row gap-4">
          <div className="flex gap-4 w-[32.5%]">
            <div className="flex w-full  flex-col gap-2"
             data-name="discord" 
             data-type="discordUrl"
             data-invalid={formValues?.discord?
               validationCheck({ type: 'discordUrl', value:formValues?.discord })?'true':'false':'false'}
            >
              <div>
                <InputBox
                  label="Discord"
                  validateOnBlur
                  placeholder="Discord URL"
                  type="text"
                  required={false}
                  onChange={inputOnChange}
                  controlName="discord"
                  value={formValues.discord}
                />
                <Validators
                  type="discordUrl"
                  value={formValues.discord}
                  message="URL not valid"
                  controlName="discord"
                  onErrors={collectErrors}
                />
              </div>
            </div>
            
          </div>
          <div className="flex gap-4 w-[32.5%]">
            <div className="flex w-full  flex-col gap-2"
             data-name="youtube" 
             data-type="youtubeUrl"
             data-invalid={formValues?.youtube?
               validationCheck({ type: 'youtubeUrl', value:formValues?.youtube })?'true':'false':'false'}
            >
              <div>
                <InputBox
                  label="Youtube"
                  validateOnBlur
                  placeholder="Youtube URL"
                  type="text"
                  required={false}
                  onChange={inputOnChange}
                  controlName="youtube"
                  value={formValues.youtube}
                />
                <Validators
                  type="youtubeUrl"
                  value={formValues.youtube}
                  message="URL not valid"
                  controlName="youtube"
                  onErrors={collectErrors}
                />
              </div>
            </div>
            
          </div>
          </div>
        </div>
      </div>
      {/* {!!(communityDetails?.collections?.length || spinnerMode)&&
      <div className="flex flex-col gap-4 community-collection mt-4">
        <div>
          <div className="section-title brd-btm w-full">Collections</div>
        </div>
        <DetailsSkeltonCard
       show={spinnerMode}
       height={"100%"}
       count={5}
       inline={true}
       details={"img-2-line"}
       noAbsolute
       containerClassName={`flex`}
     />
        {!!communityDetails?.collections?.length&&
        <div className="collection-container grid gap-3 relative mt-4">
            {[...communityDetails?.collections]?.map((list) => {
              return (
                <CollectionCard
                  showData={{
                    rofileIcon: true,
                    collectionName: true,
                    communityName: true,
                    count: false,
                    tag: true,
                    }}
                    key={list?.id}
                    row={{
                     ...list,
                      community:{name:communityDetails?.name,displayPicture256:communityDetails?.displayPicture256},
                      filePath256: list?.filePath256 ?? list?.filePath
                    }}
                            
                  />
                );
            })}
        </div>}
        </div>
          }  */}
          </>}
          {!!(activeTab==="collections")&&
          <CollectionsLists   
          spinnerMode={collectionLoader}
          count = {12}
          loadMore={loadMoreCollections}
          totalItemsCount={totalCollectionsCount}
          collectionsLoaderCount = {4}
          collections={collectionsDataLists}
          parentScrollContainerQuery={"#community-details-wrap"}
          />
          }
          </div>
      <div className="flex justify-center gap-4">
        <Button
          label="Cancel"
          onClick={gotToBack}
          className="transaction-cancel"
        />
        <Button
          className={`transaction-ok ${!isValid ? "disable" : ""}`}
          label={id?`Update`:"Add"}
          onClick={saveHandler}
        />
      </div>
    </div>
  );
};

export default EditCommunity;
